/** @jsx jsx */
import { Children } from "react"
import { jsx, Styled } from "theme-ui"
import PropTypes from "prop-types"

const List = ({ children, icon }) => (
  <ul
    sx={{
      display: ["block", "flex"],
      flexWrap: "wrap",
      padding: 0,
      margin: 0,
      listStyle: "none",
    }}
  >
    {Children.toArray(children).map((child, index) => (
      <li
        key={index}
        sx={{
          alignItems: "center",
          display: "flex",
          width: ["100%", "50%"],
          p: 3,
          "&>svg": {
            height: "100%",
            color: "dark",
            display: "inline-block",
            maxWidth: "32px",
            minWidth: "32px",
            mr: 3,
          },
        }}
      >
        {icon}
        <Styled.p sx={{ fontWeight: "bold", color: "dark" }}>{child}</Styled.p>
      </li>
    ))}
  </ul>
)

List.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
}

export default List
