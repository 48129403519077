/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import Img from "gatsby-image"

const ImageText = ({ children, image, bg, color }) => (
  <div
    sx={{
      display: ["block", "flex"],
      "&>*": {
        width: ["100%", "50%"],
      },
    }}
  >
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
        py: 2,
        px: 5,
        justifyContent: "center",
        alignItems: "flex-start",
        bg,
        color,
        "&>h2, &>h3, &>h4, &>h5, &>h6": {
          color,
        },
      }}
    >
      {children}
    </div>
    <div
      sx={{
        display: "flex",
        "&>div": {
          display: "flex",
          width: "100%",
        },
      }}
    >
      <Img fluid={image} />
    </div>
  </div>
)

ImageText.defaultProps = {
  bg: "accentOne",
  color: "background",
}

ImageText.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.object.isRequired,
  bg: PropTypes.string,
  color: PropTypes.string,
}

export default ImageText
