/** @jsx jsx */
import { Fragment } from "react"
import { jsx, Styled } from "theme-ui"
import { graphql } from "gatsby"
import VisuallyHidden from "@reach/visually-hidden"

import Layout, { Container, Row, Col } from "components/layout"
import Co2Down from "components/svgs/co2-down"
import GhgChart from "components/svgs/ghg-chart"
import Hero from "components/hero"
import ImageText from "components/image-text"
import List from "components/list"
import OwnedChart from "components/svgs/owned-chart"
import { TileLink } from "components/tiles"
import Title from "components/title"
import UnownedChart from "components/svgs/unowned-chart"

export const query = graphql`
  query ClimatePageQuery {
    heroImage: file(relativePath: { eq: "hero/climate.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    buildingsImage: file(relativePath: { eq: "hero/building.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    centre: file(relativePath: { eq: "climate/centre.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    solar: file(relativePath: { eq: "climate/solar.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ClimatePage = ({ data }) => (
  <Layout
    pageTitle="Climate & Energy"
    description="Mohawk is committed to reducing our greenhouse gas (GHG) emissions that contribute to climate change by measuring and reducing GHG emissions."
  >
    <Title icon={<Co2Down />}>Climate &amp; Energy</Title>
    <Hero fluid={data.heroImage.childImageSharp.fluid} />

    <Container>
      <div sx={{ maxWidth: ["100%", "70%"] }}>
        <Styled.p>
          As a leader in our community and the higher education sector, Mohawk
          is committed to reducing the college’s greenhouse gas (GHG) emissions
          that contribute to climate change. By measuring and reducing GHG
          emissions, we improve the sustainability of our college.
        </Styled.p>
        <Styled.p>
          Mohawk's main sources of GHGs come from heating and cooling the
          buildings, student and staff commuting, and from using electricity.
        </Styled.p>
        <Styled.p>
          Mohawk has made great strides in improving the energy efficiency of
          college operations through a combination of facility upgrades, on-site
          renewables, effective space planning, and stakeholder engagement.
        </Styled.p>
      </div>
    </Container>

    <div sx={{ bg: "muted" }}>
      <Container>
        <Styled.h2>Key Energy Initiatives</Styled.h2>
        <List icon={<Co2Down />}>
          <Fragment>
            Installation of 3,296 solar panels, enough to power 130 homes
          </Fragment>
          <Fragment>Efficient lighting upgrades</Fragment>
          <Fragment>Extension of Building Automation System (BAS)</Fragment>
          <Fragment>
            Replacement of one natural gas boiler with a 1 MW electric boiler
            and 1 MW battery storage
          </Fragment>
          <Fragment>Updated HVAC equipment</Fragment>
          <Fragment>
            Replacement of boilers and generators with more efficient and
            cleaner natural gas burning equipment
          </Fragment>
          <Fragment>
            Forming of a new Energy Conservation Committee to lead energy
            conservation initiatives
          </Fragment>
        </List>
      </Container>
    </div>

    <Title as="h2" bg="accentOne" color="background">
      GHG Inventory Results
    </Title>
    <Container>
      <Styled.h3 sx={{ textAlign: "center" }}>
        GHG Emissions by Source: 2018/2019
      </Styled.h3>
      <Row
        sx={{
          display: ["block", "flex"],
          textAlign: "center",
        }}
      >
        <Col sx={{ mb: 3, width: ["100%", "50%"] }}>
          <Styled.h4 sx={{ textAlign: "center" }}>
            Scopes 1 &amp; 2: Owned
          </Styled.h4>
          <OwnedChart />
          <VisuallyHidden>
            <table>
              <caption>
                GHG Emissions by Source: 2018/2019 - Scopes 1 &amp; 2: Owned
              </caption>
              <thead>
                <tr>
                  <th>Natural Gas</th>
                  <th>Fleet &amp; Emergency</th>
                  <th>Electricity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>88.4%</td>
                  <td>0.87%</td>
                  <td>10.72%</td>
                </tr>
              </tbody>
            </table>
          </VisuallyHidden>
        </Col>
        <Col sx={{ mb: 3, maxWidth: ["none", "50%"] }}>
          <Styled.h4 sx={{ textAlign: "center" }}>Scope 3: Not Owned</Styled.h4>
          <UnownedChart />
          <VisuallyHidden>
            <table>
              <caption>
                GHG Emissions by Source: 2018/2019 - Scope 3: Not Owned
              </caption>
              <thead>
                <tr>
                  <th>Commuting</th>
                  <th>Business Travel, Waste & Paper Use</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>97.62%</td>
                  <td>2.38%</td>
                </tr>
              </tbody>
            </table>
          </VisuallyHidden>
        </Col>
      </Row>
    </Container>

    <Container wide={true} spaced={false}>
      <ImageText image={data.solar.childImageSharp.fluid}>
        <Styled.p>
          Mohawk has made significant progress in reducing our GHG emissions.
          Since 2007, the college has reduced Scope 1 &amp; 2 emissions by 63%.
        </Styled.p>
        <Styled.p>
          These emissions result directly from college operations (e.g. heating,
          cooling, electricity and fleet fuel). This reduction demonstrates that
          the college's investments in green initiatives are working to create a
          more sustainable campus.
        </Styled.p>
        <Styled.p>
          Since 2012, the campus' population has grown significantly. While
          Mohawk has continued to reduce its GHG emissions on campus, the
          quantity and share of emissions from student and staff commuting has
          risen sharply. This is because the number, length and frequency of
          commutes has grown.
        </Styled.p>
        <Styled.p>
          Although transportation is outside the direct control of the college,
          Mohawk is committed to measuring, reporting and supporting programs to
          reduce Scope 3 emissions caused by commuting.
        </Styled.p>
      </ImageText>
    </Container>

    <Container>
      <Styled.h3 sx={{ textAlign: "center" }}>
        GHG Emissions over Time: All Sources
      </Styled.h3>
      <GhgChart />
      <VisuallyHidden>
        <table>
          <caption>GHG Emissions Over Time – All Sources</caption>
          <thead>
            <tr>
              <th>Year</th>
              <th>Scope One as percent of total</th>
              <th>Scope Two as percent of total</th>
              <th>Scope Three as percent of total</th>
              <th>Total Emissions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2007</td>
              <td>26.53%</td>
              <td>35.48%</td>
              <td>37.99%</td>
              <td>
                13743.63 lbs of CO<sub>2</sub>e
              </td>
            </tr>
            <tr>
              <td>2012</td>
              <td>26.89%</td>
              <td>20.16%</td>
              <td>52.95%</td>
              <td>
                10454 lbs of CO<sub>2</sub>e
              </td>
            </tr>
            <tr>
              <td>2018/2019</td>
              <td>19.84%</td>
              <td>2.38%</td>
              <td>77.78%</td>
              <td>
                14328.08 lbs of CO<sub>2</sub>e
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <caption>Percent change in Scopes 1 &amp; 2 Over Time</caption>
          <thead>
            <tr>
              <th>Year</th>
              <th>Percent Change</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>2007-2012</td>
              <td>-42.28%</td>
            </tr>
            <tr>
              <td>2012-2018/2019</td>
              <td>-35.26%</td>
            </tr>
            <tr>
              <td>2007-2018/2019</td>
              <td>-62.64%</td>
            </tr>
          </tbody>
        </table>
      </VisuallyHidden>
    </Container>

    <Container wide={true} spaced={false}>
      <ImageText image={data.centre.childImageSharp.fluid}>
        <Styled.h2>
          <Styled.p as="span">Spotlight on</Styled.p>
          <br />
          Centre for Climate Change Management at Mohawk College
        </Styled.h2>
        <Styled.h4 as="h3">
          Supporting the transition to a thriving, low-carbon economy in the
          greater Hamilton-Burlington area
        </Styled.h4>
        <Styled.p>
          Addressing climate change requires unprecedented levels of
          collaboration and partnership. Mohawk launched the Centre for Climate
          Change Management in late 2017 to connect industry, community and
          public sector organizations to co-create community-centered climate
          solutions.
        </Styled.p>
        <Styled.p>
          The Centre brings together partners to collaborate and design climate
          change and sustainability solutions that improve our neighbourhoods,
          businesses and public institutions.
        </Styled.p>
        <Styled.p>
          The Centre is uniquely situated at Mohawk College, enabling
          connections to applied research, training solutions, award-winning
          project managers, experiential learning programs, and a campus that
          acts as a living lab where solutions are piloted and studied on
          campus.
        </Styled.p>
      </ImageText>
    </Container>

    <Container>
      <Styled.h2>Continue Reading</Styled.h2>
      <TileLink
        to="/buildings"
        image={data.buildingsImage.childImageSharp.fluid}
      >
        Buildings &amp; Grounds
      </TileLink>
    </Container>
  </Layout>
)

export default ClimatePage
