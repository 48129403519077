import React from "react"

const Co2Down = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="69"
    height="69"
    viewBox="0 0 69 69"
    fill="none"
  >
    <path
      fill="currentColor"
      d="M37.262 48.94v12.17l5.62-6.408c1.473 1.562 2.414 2.556 3.283 3.478C42.445 61.782 38.674 65.444 35 69L23.78 57.953c.876-.789 1.912-1.727 3.294-2.97 1.442 1.688 3.115 3.65 4.793 5.611.242-.15.49-.299.732-.448V43.18c3.537.743 6.766 1.43 10 2.103 4.872 1.013 9.506.751 13.627-2.421 3.898-3 4.915-5.96 3.287-10.551-.448-1.267.319-1.651.986-2.268 3.325-3.049 4.573-6.803 3.537-11.13-1.02-4.263-3.852-7.005-8.098-8.287-3.051-.923-5.97-.317-8.82 1.02-.853-.927-1.609-1.83-2.451-2.649-7.358-7.136-19.662-5.597-24.823 3.195-.952 1.621-1.806 2.152-3.75 1.928C9.879 13.405 4.72 18.535 5.64 24.27c.41 2.567 1.958 4.372 4.037 5.81.608.419 1.26.774 1.856 1.136-.596 1.58-1.377 2.97-1.635 4.45-1.07 6.105 4.265 10.992 10.402 9.598 1.529-.347 3.066-.747 4.504-1.348 1.12-.467 1.715-.31 2.228.75.402.826.952 1.585 1.442 2.377-7.932 4.233-14.876 3.92-19.586-.908-3.267-3.348-4.19-7.334-3.465-11.84.114-.703-.11-1.711-.58-2.212-3.712-3.96-4.934-8.511-3.086-13.566C3.624 13.406 7.627 10.51 13.02 9.8c2.14-.28 3.537-.971 4.926-2.649C25.133-1.53 38.37-2.417 47.11 5.101c.994.855 1.875 1.105 3.161.9 6.71-1.069 13.085 1.864 16.425 7.45 3.438 5.75 2.891 12.804-1.317 18.135-.437.553-.74 1.465-.626 2.133.751 4.44-.775 8.12-3.913 11.123-4.338 4.147-9.563 6.094-15.67 5.294-2.607-.347-5.206-.784-7.908-1.195z"
    ></path>
    <path
      fill="currentColor"
      d="M38.096 34.728c-4.873-.019-8.28-3.397-8.273-8.205.007-4.749 3.514-8.22 8.322-8.227 4.865-.008 8.39 3.516 8.368 8.361-.023 4.745-3.51 8.09-8.417 8.07zm-4.808-8.156c.01 2.918 2.037 5.073 4.808 5.114 2.835.041 4.986-2.181 4.99-5.155.004-2.945-2.182-5.156-5.036-5.097-2.808.06-4.774 2.178-4.762 5.138zm-4.851-3.344c-3.445-2.216-6.269-2.294-8.083-.434-1.723 1.764-1.882 5.022-.349 7.055 1.727 2.29 4.319 2.368 8.448.25.588 3.523.656 4.128-3.62 4.57-4.657.477-7.902-1.69-9.01-5.598-1.158-4.08.46-8.115 3.988-9.852 2.247-1.106 4.581-1.147 6.98-.49 2.614.722 1.38 2.687 1.646 4.499zm19.605 14.437V36.38l3.048-2.776c.258-.247.478-.47.652-.665.179-.198.312-.392.403-.583.09-.19.136-.396.136-.62 0-.247-.057-.46-.17-.64a1.134 1.134 0 00-.467-.414 1.5 1.5 0 00-.672-.145c-.262 0-.49.052-.687.156a1.103 1.103 0 00-.451.449 1.42 1.42 0 00-.16.695H47.96c0-.553.125-1.032.38-1.435a2.498 2.498 0 011.066-.942c.455-.22.983-.332 1.578-.332.611 0 1.147.105 1.602.317.455.213.808.509 1.062.882.255.378.38.807.38 1.293 0 .317-.065.631-.19.942-.125.31-.353.653-.675 1.03-.323.378-.778.83-1.367 1.357l-1.248 1.207v.056h3.594v1.457h-6.099v-.004z"
    ></path>
  </svg>
)

export default Co2Down
